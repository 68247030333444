<template>
  <div>
    <title-bar
      :title="$t('messages.resortUpdate')"
    />
    <b-card v-if="resort">
      <b-tabs pills v-model="tabIndex">
        <b-tab v-for="tab of tabs" :key="tab.id" :title="tab.title"></b-tab>
        <template #tabs-end>
          <b-nav-item class="ms-auto" v-if="tabIndex === 1 && showRoomForm" href="#" role="presentation"
                      @click="() => { showRoomForm = false }">{{ $t('messages.back') }}
          </b-nav-item>
        </template>
      </b-tabs>
      <resort-form
        class="pt-4"
        v-if="tabIndex === 0"
        :resort-obj="resort"
        :action="action"
      />
      <room-list
        v-if="tabIndex === 1 && !showRoomForm"
        :resort="resort.id"
        @addRoom="proceedRoomForm"
        @roomSelected="proceedRoomForm"
      />
      <room-form
        v-if="tabIndex === 1 && showRoomForm"
        :room-obj="room"
      />

    </b-card>
  </div>
</template>

<script>
import ResortForm from "./components/ResortForm.vue";
import RoomForm from "./components/RoomForm.vue";
import RoomList from "./components/RoomList.vue";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import TitleBar from "@/components/TitleBar.vue";

export default {
  components: {
    ResortForm,
    RoomForm,
    RoomList,
    TitleBar
  },
  data() {
    return {
      action: 'update',
      tabs: [
        {
          id: 0,
          title: "Resort",
        },
        {
          id: 1,
          title: "Rooms",
        }
      ],
      tabIndex: 0,
      resort: null,
      room: null,
      showRoomForm: false,
    };
  },
  watch: {
    tabIndex() {
      this.showRoomForm = false
    }
  },
  created() {
    this.loadResort();
  },
  methods: {
    loadResort() {
      this.$Resorts
        .getResource({id: this.$route.params.id, params: {pagination: false, width: 800, format: 'jpg'}})
        .then((response) => {
          if (response.status === 200) {
            this.resort = response.data;
            this.resort.statusSelect = {
              label: this.$helper.getEnumTranslation('resort_status', this.resort.status, this.$i18n.locale),
              id: this.resort.status
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    proceedRoomForm(data) {
      if (data === "add") {
        this.room = {
          resort: this.resort["@id"],
          name: null,
          description: null,
          amenitiesFacilities: [],
          children: null,
          infants: null,
          accommodationType: null
        };
        this.showRoomForm = true;
      } else {
        this.$Rooms.getResourceByUrl({
          url: data,
          params: {pagination: false, width: 800, format: 'jpg'}
        }).then((response) => {
          if (response.status === 200) {
            this.room = response.data;
            this.showRoomForm = true;
          }
        });
      }
    },
  },
};
</script>
