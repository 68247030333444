<template>
  <div v-if="rooms" class="custom-table no-box-shadow">
    <table-actions
        :actions="['search', 'perPage']"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
    />

    <table class="table table-hover table-bordered table-responsive-sm">
      <thead class="thead-dark">
      <tr>
          <th>{{ $t("forms.name") }}</th>
          <th style="width: 120px">{{ $t("forms.actions") }}</th>
      </tr>
      </thead>
      <nested-draggable :items="rooms" @itemEdit="editRoom" @itemOrderChanged="updateRoomsPosition"/>
    </table>
    <div class="d-flex">
      <b-button
        variant="primary"
        class="me-auto"
        @click="$emit('addRoom', 'add')"
      >
        {{ $t("messages.roomAdd") }}
      </b-button>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import nestedDraggable from "./RoomListNested.vue";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";

export default {
  components: {
    nestedDraggable,
    TablePagination,
    TableActions,
  },
  props: {
    resort: { type: String, required: true },
  },
  data() {
    return {
      search: "",
      isLoading: false,
      rooms: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadRooms();
  },
  methods: {
    loadRooms() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Resorts
        .getResourceByUrl({url: `/resorts/${this.resort}/rooms`, params}, 'id_name')
        .then((response) => {
          this.pages = response.data["hydra:totalItems"];
          this.rooms = response.data["hydra:member"];
        });
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadRooms();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadRooms();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadRooms();
    },
    editRoom(id) {
      this.$emit('roomSelected', id)
    },
    updateRoomsPosition() {
        let body = this.rooms.map((room) => room['@id'])
        this.$Resorts
          .updateResourceByUrl({url: `/resorts/${this.resort}/rooms-sort`, body})
          .then((response) => {
              if (response.status === 200) {
                  const toast = useToast();
                  this.$helper.showToast(
                      toast,
                      Toast,
                      this.$t("messages.resortUpdated"),
                      "",
                      "success"
                  );
              }
          })
          .catch((error) => {
              if (error.response) {
                  const toast = useToast();
                  this.$helper.showToast(
                      toast,
                      Toast,
                      error.response.data["hydra:title"],
                      error.response.data["hydra:description"],
                      "danger"
                  );
              }
          });
    },
  },
};
</script>
