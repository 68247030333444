<template>
  <b-card class="card-gray mt-4">
    <h5 class="mb-4" v-if="galleryTitle">{{ galleryTitle }}</h5>
    <div class="gallery">
      <draggable
        v-if="imageFiles && imageFiles.length"
        :list="imageFiles"
        item-key="id"
        class="gallery-image"
      >
        <template #item="{ element }">
          <div class="gallery-image-item">
            <image-upload
              :for-banner="true"
              :resource="this.resource"
              :fileAttribute="this.fileAttribute"
              :preview-image="element.url"
              :label="element.url ? '' : $t('messages.imageAdd')"
              :multiple="!single"
              @fileUploaded="ilustrationImageChange"
            />
            <div
              class="trash"
              v-if="element.url"
              @click="removeFile(element.id)"
            >
              <bootstrap-icon icon="trash" size="1x" />
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <b-button
      v-if="!(single && files.length >= 1) || !single"
      variant="gray"
      :disabled="!(enableAdding || files.length === 0)"
      @click="addFile"
    >
      <bootstrap-icon icon="plus" />
      {{ $t('messages.imageAdd') }}
    </b-button>
  </b-card>
</template>

<script>
import { BButton } from "bootstrap-vue-3";
import ImageUpload from "@/components/ImageUpload.vue";
import draggable from "vuedraggable";
import ResourceUtils from "@/mixins/ResourceUtils"

export default {
  components: {
    BButton,
    ImageUpload,
    draggable,
  },
  props: {
    modelValue: { type: [Array, Object], required: true },
    single: { type: Boolean, default: false },
    galleryTitle: { type: String, default: "" },
    resource: {type: String, default: null},
    fileAttribute: {type: String, default: null},
  },
  mixins: [ResourceUtils],
  data() {
    let arr = [];
    if (this.modelValue) {
      arr = [this.modelValue];
    }
    return {
      files: Array.isArray(this.modelValue) ? this.modelValue : arr,
      enableAdding: true,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        if (this.single) {
          if (val) {
            this.files = [val];
          } else {
            this.files = [];
          }
        } else {
          this.files = val;
        }
      },
    },
  },
  computed: {
    imageFiles() {
      let files = []
      this.files.forEach(element => {
        if(element.mimeType.includes("image")) {
          files.push(element)
        }
      });
      return files
    }
  },
  methods: {
    ilustrationImageChange(data) {
      if(Array.isArray(data)) {
        this.files.pop()
        data.forEach(element => {
          this.files.push(element)
        })
      } else {
        this.files[this.files.length - 1].id = data.id;
        this.files[this.files.length - 1]["@id"] = data["@id"];
        this.files[this.files.length - 1].url = data.url;
        this.files[this.files.length - 1].mimeType = data.mimeType;
      }
      this.enableAdding = true;
      if (this.single) {
        this.$emit("update:modelValue", this.files[0]);
      } else {
        this.$emit("update:modelValue", this.files);
      }
    },
    addFile() {
      const obj = {};
      obj["@id"] = -1;
      obj.url = null;
      obj.mimeType = "image"
      this.files.push(obj);
      this.enableAdding = false;
    },
    removeFile(id) {
      this.delete(this.$Files, id, this.$t('messages.fileDeleted'));
      this.files = this.files.map((item) => ({ ...item })).filter((item) => item.id !== id);
      if (this.single) {
        this.$emit("update:modelValue", null);
      } else {
        this.$emit("update:modelValue", this.files);
      }
    },
  },
};
</script>
