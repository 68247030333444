<template>
    <draggable class="dragArea" tag="tbody" :list="items" :group="{ name: 'g1' }" @change="onDragUpdate">
        <tr v-for="(el, index) in items" :key="index">
            <td class="">
                <div>
                    <div class="dragg">
                        <bootstrap-icon icon="arrows-move" size="1x" />
                    </div>
                    <a
                        href="javascript:void(0)"
                        @click="editFromNested(el['@id'])"
                    >{{ el.name }}</a
                    >
                </div>
            </td>
            <td>
                <div class="just-buttons">
                    <bootstrap-icon
                        icon="pencil"
                        size="1x"
                        @click="editFromNested(el['@id'])"
                    />
                </div>
            </td>
        </tr>
    </draggable>
</template>
<script>
import { VueDraggableNext } from "vue-draggable-next";

export default {
    name: "nested-draggable",
    props: {
        items: {
            required: true,
            type: Array,
        },
    },
    components: {
        draggable: VueDraggableNext,
    },
    methods: {
        editFromNested(id) {
            this.$emit("itemEdit", id);
        },
        onDragUpdate(ev) {
            this.$emit("itemOrderChanged", ev);
        },
    },
};
</script>
<style lang="scss" scoped>
.dragArea {
    border-top: 0;
   td {
       > div {
           display: flex;
           align-items: center;
           justify-content: flex-start;
       }
       .dragg {
           margin-right: 10px;
       }
   }
}
</style>
