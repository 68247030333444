<template>
  <b-form v-if="resort" class="resort-form" @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('forms.resortName')"
            label-size="lg"
            label-cols-lg="4"
            content-cols-lg="8"
            class="align-items-center"
            :class="{ row, error: v$.resort.name.$errors.length }"
          >
            <b-form-input @blur="presetSlug" v-model="resort.name"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.customLinkUrl')"
            label-cols-lg="4"
            content-cols-lg="8"
            :class="{ row, error: v$.resort.url.$errors.length }"
          >
            <b-form-input @blur="slugifyURL" v-model="resort.url"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.url.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :class="{ row, error: v$.resort.statusSelect.$errors.length }"
            :label="$t('forms.status')"
            label-cols-lg="4"
            content-cols-lg="8"
          >
            <v-select
              id="h-status"
              v-model="resort.statusSelect"
              :options="statuses"
            />
            <div
              class="input-errors"
              v-for="error of v$.resort.statusSelect.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.location')"
            label-cols-lg="4"
            content-cols-lg="8"
            :class="{ row, error: v$.resort.location.$errors.length }"
          >
            <b-form-input v-model="resort.location"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.location.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.rates')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.stars.$errors.length }"
          >
            <v-select
              :searchable="false"
              :clearable="false"
              :options="stars"
              v-model="resort.stars"
            >
            </v-select>
            <div
              class="input-errors"
              v-for="error of v$.resort.stars.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.review')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.review.$errors.length }"
          >
            <b-form-input v-model="resort.review"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.review.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.bankTransferLimit')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.bankTransferLimit.$errors.length }"
          >
            <b-form-input type="number" v-model="resort.bankTransferLimit"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.bankTransferLimit.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.infantAgeLimit')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.infantAgeLimit.$errors.length }"
          >
            <b-form-input type="number" v-model="resort.infantAgeLimit"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.infantAgeLimit.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.booking')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.availableUntil.$errors.length }"
          >
            <datepicker
              inputFormat="dd. MM. yyyy"
              v-model="resort.availableUntil"
              class="form-input"
            />
            <div
              class="input-errors"
              v-for="error of v$.resort.availableUntil.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.latitude')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.latitude.$errors.length }"
          >
            <b-form-input v-model="resort.latitude"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.latitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.longitude')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.longitude.$errors.length }"
          >
            <b-form-input v-model="resort.longitude"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.longitude.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.listingText')"
            label-cols-lg="4"
            content-cols-lg="4"
          >
            <b-form-input type="text" v-model="resort.listingText"/>
          </b-form-group>
          <b-form-group
            :label="$t('forms.bookBefore')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.resort.bookBefore.$errors.length }"
          >
            <b-form-input v-model="resort.bookBefore"/>
            <div
              class="input-errors"
              v-for="error of v$.resort.bookBefore.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-card class="card-gray mt-4">
        <b-row>
          <b-col cols="12" sm="4">
            <h5 class="mb-4">{{ $t("forms.boatTransfer") }}</h5>
            <b-card class="no-box-shadow">
              <b-form-group
                id="input-group-3"
                :label="$t('forms.publish')"
                label-for="published"
              >
                <b-form-checkbox
                  v-model="resort.speedboatTransfer.applicable"
                  name="speedboat-applicable"
                  switch
                />
              </b-form-group>
              <b-row>
                <b-col cols="8">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.item")
                    }}</label>
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.adult')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.child')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.infant')"
                  />
                </b-col>
                <b-col cols="4">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.cost")
                    }}</label>
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.speedboatTransfer.adultPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.speedboatTransfer.childPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.speedboatTransfer.infantPrice"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" sm="4">
            <h5 class="mb-4">{{ $t("forms.planeTransfer") }}</h5>
            <b-card class="no-box-shadow">
              <b-form-group
                id="input-group-3"
                :label="$t('forms.publish')"
                label-for="published"
              >
                <b-form-checkbox
                  v-model="resort.seaplaneTransfer.applicable"
                  name="seaplaneTransfer-applicable"
                  switch
                />
              </b-form-group>
              <b-row>
                <b-col cols="8">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.item")
                    }}</label>
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.adult')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.child')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.infant')"
                  />
                </b-col>
                <b-col cols="4">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.cost")
                    }}</label>
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.seaplaneTransfer.adultPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.seaplaneTransfer.childPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.seaplaneTransfer.infantPrice"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" sm="4">
            <h5 class="mb-4">{{ $t("forms.domesticFlightTransfer") }}</h5>
            <b-card class="no-box-shadow">
              <b-form-group
                id="input-group-3"
                :label="$t('forms.publish')"
                label-for="published"
              >
                <b-form-checkbox
                  v-model="resort.domesticFlightTransfer.applicable"
                  name="domesticFlightTransfer-applicable"
                  switch
                />
              </b-form-group>
              <b-row>
                <b-col cols="8">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.item")
                    }}</label>
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.adult')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.child')"
                  />
                  <b-form-input
                    readonly
                    class="mb-2"
                    :placeholder="$t('forms.infant')"
                  />
                </b-col>
                <b-col cols="4">
                  <label class="label-sm mb-1" for="">{{
                      $t("forms.cost")
                    }}</label>
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.domesticFlightTransfer.adultPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.domesticFlightTransfer.childPrice"
                  />
                  <b-form-input
                    class="mb-2"
                    type="number"
                    v-model="resort.domesticFlightTransfer.infantPrice"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="no-box-shadow mt-4">
        <h5 class="mb-3">{{ $t("forms.benefits") }}</h5>
        <b-row class="benefits">
          <b-col v-for="(benefit, index) of benefits" :key="index">
            <b-form-checkbox :value="benefit.id" v-model="resort.benefits"
            >{{ benefit.label }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="card-gray mt-4">
        <h5 class="mb-2">{{ $t("forms.content") }}</h5>
        <b-form-group>
          <label class="label-sm mb-1">{{ $t("forms.description") }}</label>
          <tinymce
            :options="{ height: 200 }"
            :content="resort.description"
            @editorContentChanged="updateDescription"
          />
        </b-form-group>
        <b-form-group>
          <label class="label-sm mb-1">{{ $t("forms.accommodation") }}</label>
          <tinymce
            :options="{ height: 200 }"
            :content="resort.accommodationDescription"
            @editorContentChanged="updateAccommodation"
          />
        </b-form-group>
        <b-form-group>
          <label class="label-sm mb-1">{{ $t("forms.transfers") }}</label>
          <tinymce
            :options="{ height: 200 }"
            :content="resort.transferDescription"
            @editorContentChanged="updateTransfer"
          />
        </b-form-group>
        <b-form-group>
          <label class="label-sm mb-1">{{
              $t("forms.cancellationPolicy")
            }}</label>
          <tinymce
            :options="{ height: 200 }"
            :content="resort.cancellationPolicy"
            @editorContentChanged="updateCancellationPolicy"
          />
        </b-form-group>
      </b-card>

      <gallery
        v-if="this.resort['@id']"
        v-model="file"
        :resource="this.resort['@id']"
        file-attribute="FILE"
        :single="true"
        galleryTitle="Main image"
      />
      <gallery
        v-if="this.resort['@id']"
        v-model="files"
        :resource="this.resort['@id']"
        file-attribute="FILES"
        galleryTitle="Image gallery"
      />

      <b-card class="card-gray my-4 facilities_services">
        <h5 class="mb-4">{{ $t("forms.facilities") }}</h5>
        <b-card
          class="no-box-shadow mb-4"
          v-if="resort.facilitiesServices && resort.facilitiesServices.length"
        >
          <div
            v-for="(fs, index) of resort.facilitiesServices"
            :key="index"
            class="facility d-flex align-items-center"
            :class="{ 'mb-2': index !== resort.facilitiesServices.length - 1 }"
          >
            <v-select
              :searchable="false"
              :clearable="false"
              :options="facilities"
              v-model="fs.type"
            >
            </v-select>
            <b-form-input v-model="fs.description" class="mx-4"/>
            <b-form-checkbox v-model="fs.additionalCharges"
            >Additional charge
            </b-form-checkbox>
            <bootstrap-icon @click="removeFacility(index)" icon="x-lg"/>
          </div>
        </b-card>
        <b-button @click="addFacility" variant="gray">
          <bootstrap-icon icon="plus"/>
          {{ $t("forms.addItem") }}
        </b-button>
      </b-card>
      <b-card v-if="this.resort['@id']" class="card-gray my-4">
        <h5 class="mb-4">{{ $t("forms.documents") }}</h5>
        <document-list
          v-model="files"
          :resource="this.resort['@id']"
          file-attribute="FILES"
        />
      </b-card>
      <b-button variant="primary" @click="onSubmit">
        <bootstrap-icon v-if="!resort.id" icon="plus"/>
        {{ !resort.id ? $t("messages.resortAdd") : $t("messages.resortSave") }}
      </b-button>
    </b-container>
  </b-form>
</template>

<script>
import {
  BRow,
  BForm,
  BCol,
  BContainer,
  BFormGroup,
  BButton,
} from "bootstrap-vue-3";
import Datepicker from "vue3-datepicker";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import Gallery from "./Gallery.vue";
import {integer, required} from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import DocumentList from "./DocumentList.vue";

export default {
  props: {
    resortObj: {
      type: Object,
      default() {
      },
    },
    action: {type: String, default: null},
  },
  components: {
    BRow,
    BContainer,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    Datepicker,
    tinymce,
    vSelect,
    Gallery,
    DocumentList,
  },
  data() {
    return {
      success: false,
      resort: JSON.parse(JSON.stringify(this.resortObj)),
      error: null,
      benefits: [],
      stars: [],
      facilities: [],
      files: [],
      file: null,
      date: new Date(),
      statuses: []
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.getEnumList(["resort_status"], this.statuses);
    this.getEnumList("resort_benefits", this.benefits);
    this.getEnumList("resort_stars", this.stars);
    this.getEnumList("facility_service_type", this.facilities);
    this.setSelectDataForResort();

    if (this.resort.id) {
      this.$Resorts
        .getResourceFiles({id: this.resort.id, params: {pagination: false, width: 800, format: 'jpg'}})
        .then((response) => {
          if (response.status === 200) {
            this.files = response.data["hydra:member"];
          }
        })
        .catch((error) => {
          if (error.response) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    }
    if (this.resort.file) {
      this.file = {
        "@id": this.resort.file,
        url: this.resort.fileUrl,
        id: this.resort.fileId,
        mimeType: "image",
      };
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      resort: {
        name: {required},
        url: {required},
        location: {required},
        stars: {required},
        review: {required},
        availableUntil: {required},
        latitude: {required},
        longitude: {required},
        bookBefore: {required},
        infantAgeLimit: {required, integer},
        bankTransferLimit: {required, integer},
        statusSelect: {required}
      },
    };
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = JSON.parse(JSON.stringify(this.resort));
        if (body.stars) {
          body.stars = body.stars.id;
        }
        if (this.resort.statusSelect) {
          body.status = this.resort.statusSelect.id
        }
        if (
          this.resort.facilitiesServices &&
          this.resort.facilitiesServices.length
        ) {
          body.facilitiesServices = [];
          this.resort.facilitiesServices.forEach((fs) => {
            body.facilitiesServices.push({
              type: fs.type.id,
              description: fs.description,
              additionalCharges: fs.additionalCharges,
            });
          });
        }

        body.speedboatTransfer.adultPrice =
          body.speedboatTransfer.adultPrice.toString();
        body.speedboatTransfer.childPrice =
          body.speedboatTransfer.childPrice.toString();
        body.speedboatTransfer.infantPrice =
          body.speedboatTransfer.infantPrice.toString();

        body.seaplaneTransfer.adultPrice =
          body.seaplaneTransfer.adultPrice.toString();
        body.seaplaneTransfer.childPrice =
          body.seaplaneTransfer.childPrice.toString();
        body.seaplaneTransfer.infantPrice =
          body.seaplaneTransfer.infantPrice.toString();

        body.domesticFlightTransfer.adultPrice =
          body.domesticFlightTransfer.adultPrice.toString();
        body.domesticFlightTransfer.childPrice =
          body.domesticFlightTransfer.childPrice.toString();
        body.domesticFlightTransfer.infantPrice =
          body.domesticFlightTransfer.infantPrice.toString();

        body.review = body.review.toString();
        body.infantAgeLimit = parseInt(body.infantAgeLimit, 10);
        body.bankTransferLimit = parseInt(body.bankTransferLimit, 10);

        if (this.action === "update") {
          this.$Resorts
            .updateResourceByUrl({url: this.resort["@id"], body})
            .then((response) => {
              if (response.status === 200) {
                this.resort = response.data;
                this.setSelectDataForResort();
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.resortUpdated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }

        if (this.action === "add") {
          this.$Resorts
            .createResource({body})
            .then((response) => {
              if (response.status === 201) {
                this.resort = response.data;
                this.setSelectDataForResort();
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.resortCreated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }
      }
    },
    addFacility() {
      if (!this.resort.facilitiesServices) {
        this.resort.facilitiesServices = [];
      }
      this.resort.facilitiesServices.push({
        type: null,
        description: "",
        additionalCharges: false,
        resort: this.resort["@id"] ? this.resort["@id"] : null,
      });
    },
    removeFacility(index) {
      this.resort.facilitiesServices.splice(index, 1);
    },
    setSelectDataForResort() {
      if (this.resort.stars) {
        this.resort.stars = {
          id: this.resort.stars,
          label: this.$helper.getEnumTranslation(
            "resort_stars",
            this.resort.stars
          ),
        };
      }

      if (this.resort.status) {
        this.resort.statusSelect = {
          id: this.resort.status,
          label: this.$helper.getEnumTranslation(
            "resort_status",
            this.resort.status
          ),
        };
      }

      if (
        this.resort.facilitiesServices &&
        this.resort.facilitiesServices.length
      ) {
        this.resort.facilitiesServices.forEach((fs) => {
          fs.type = {
            id: fs.type,
            label: this.$helper.getEnumTranslation(
              "facility_service_type",
              fs.type
            ),
          };
        });
      }

      if (this.resort.availableUntil) {
        this.resort.availableUntil = new Date(this.resort.availableUntil);
        this.resort.availableUntil = new Date(
          this.resort.availableUntil.getTime() +
          Math.abs(this.resort.availableUntil.getTimezoneOffset() * 60000)
        );
      }
    },
    updateDescription(content) {
      this.resort.description = content;
    },
    updateAccommodation(content) {
      this.resort.accommodationDescription = content;
    },
    updateTransfer(content) {
      this.resort.transferDescription = content;
    },
    updateCancellationPolicy(content) {
      this.resort.cancellationPolicy = content;
    },
    presetSlug() {
      if (this.resort.url.length === 0) {
        this.resort.url = this.$helper.slugifyURL(this.resort.name);
      }
    },
    slugifyURL() {
      if (this.resort.url.length !== 0) {
        this.resort.url = this.$helper.slugifyURL(this.resort.url);
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
