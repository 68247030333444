<template>
  <b-card v-if="room" class="card-gray mt-4">
    <b-modal
      v-model="modalShow"
      modal-class="dates-dialog"
      cancel-title="Cancel"
      ok-title="Set dates"
      @ok="setOverrideDates()"
    >

      <b-form-group label="From" class="me-4">
        <datepicker
          v-model="overrideFromDate"
          inputFormat="dd. MM. yyyy"
          class="form-input"
        />
      </b-form-group>
      <b-form-group label="To" class="me-4">
        <datepicker
          v-model="overrideToDate"
          inputFormat="dd. MM. yyyy"
          class="form-input"
        />
      </b-form-group>
      <b-form-group label="Price" class="me-4">
        <b-form-input v-model="overridePrice"/>
      </b-form-group>
      <b-form-group label="Discounted price" class="me-4">
        <b-form-input v-model="overrideDiscountedPrice"/>
      </b-form-group>

    </b-modal>
    <h5>{{ $t("forms.rates") }}</h5>
    <div v-if="variants.length" class="rates">
      <div v-for="(variant, index) of variants" :key="index" class="rate">
        <div class="rate-header">
          <a
            v-b-toggle
            :href="`#mp-${index}`"
            @click.prevent
            class="rate-header-title collapsed"
          >
            <bootstrap-icon icon="chevron-down"/>
            {{ variant.name }}
          </a>
          <div class="rate-header-actions">
            <bootstrap-icon @click="editVariant(variant)" icon="pen"/>
            <delete-btn @pressDelete="removeVariant(variant)"/>
          </div>
        </div>
        <b-collapse :id="`mp-${index}`">
          <div class="rate-body">
            <b-card class="no-box-shadow">
              <div
                v-for="(mp, ix) of variant.mealPlans"
                :key="ix"
                class="meal-plans"
              >
                <div class="meal-plan">
                  <b-form-group label="Meal Plan" class="me-2 col-3">
                    <v-select
                      :searchable="false"
                      :clearable="false"
                      :options="mealPlans"
                      v-model="mp.type"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-group label="Valid from" class="me-2 col-2">
                    <datepicker
                      v-model="mp.fromDate"
                      inputFormat="dd. MM. yyyy"
                      class="form-input"
                    />
                  </b-form-group>
                  <b-form-group label="Valid to" class="me-2 col-2">
                    <datepicker
                      v-model="mp.toDate"
                      inputFormat="dd. MM. yyyy"
                      class="form-input"
                    />
                  </b-form-group>
                  <b-form-group label="Price" class="me-2 col-2">
                    <b-form-input v-model="mp.price"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Discounted price" class="me-2 col-2">
                    <b-form-input v-model="mp.discountedPrice"></b-form-input>
                  </b-form-group>

                  <div>
                    <b-button
                      v-if="mp.id"
                      @click="showModal(mp)"
                      variant="gray"
                    >
                      {{ $t("messages.mealPlanSetPriceOverrides") }}
                    </b-button>
                    <b-button
                      @click="saveMealPlan(variant.mealPlans, mp, ix)"
                      variant="gray"
                      class="mx-2"
                    >
                      {{ mp.id ? "Save" : "Add" }}
                    </b-button>
                    <delete-btn
                      icon="x-lg"
                      @pressDelete="removeMealPlan(variant.mealPlans, mp, ix)"
                    />
                  </div>
                  <calendar class="mt-2" v-if="mp.id" :mealPlan="mp"/>
                </div>
              </div>
              <b-button
                @click="addMealPlan(variant.mealPlans, variant)"
                variant="gray"
                class="m-3"
              >
                <bootstrap-icon icon="plus"/>
                {{ $t("messages.mealPlanAdd") }}
              </b-button>
            </b-card>
          </div>
        </b-collapse>
      </div>
    </div>
    <b-button @click="addNewVariant" variant="gray" class="mt-2">
      <bootstrap-icon icon="plus"/>
      {{ $t("messages.roomVariantAdd") }}
    </b-button>
  </b-card>
  <variant-form
    v-if="room"
    :room="room"
    :variantObj="variant"
    :show="showVariantForm"
    @closed="showVariantForm = false"
    @variantCreated="loadVariants"
    @variantUpdated="loadVariants"
  />
</template>

<script>

import {BFormGroup, BFormInput, BButton, BCollapse} from "bootstrap-vue-3";
import vSelect from "vue-select";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import Datepicker from "vue3-datepicker";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import VariantForm from "./VariantForm.vue";
import Calendar from "./Calendar.vue";
import ListUtils from "@/mixins/ListUtils";

export default {
  props: {
    room: {type: String, required: true},
  },
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BCollapse,
    vSelect,
    DeleteBtn,
    VariantForm,
    Datepicker,
    Calendar,
  },
  data() {
    return {
      error: null,
      variants: [],
      mealPlans: [],
      showVariantForm: false,
      modalShow: false,
      overrideFromDate: null,
      overrideToDate: null,
      overridePrice: null,
      overrideDiscountedPrice: null,
      variant: {},
      selectedMealPlan: null,
    };
  },
  mixins: [ListUtils],
  created() {
    this.getEnumList("meal_plan_type", this.mealPlans);
    if (this.room) {
      this.loadVariants();
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {};
  },
  methods: {
    loadVariants() {
      this.$Rooms
        .getResourceByUrl({url: `${this.room}/variants`, params: {'order[name]': 'asc'}})
        .then((response) => {
          this.variants = response.data["hydra:member"];
          this.variants.forEach((v) => {
            if (v.mealPlans && v.mealPlans.length) {
              v.mealPlans.forEach((mp) => {
                mp.type = {
                  id: mp.type,
                  label: this.$helper.getEnumTranslation(
                    "meal_plan_type",
                    mp.type
                  ),
                };
                if (mp.fromDate) {
                  mp.fromDate = new Date(mp.fromDate);
                  mp.fromDate = new Date(mp.fromDate.getTime() + Math.abs(mp.fromDate.getTimezoneOffset() * 60000));
                }
                if (mp.toDate) {
                  mp.toDate = new Date(mp.toDate);
                  mp.toDate = new Date(mp.toDate.getTime() + Math.abs(mp.toDate.getTimezoneOffset() * 60000));
                }
              });
            }
          });
        });
    },
    removeVariant(variant) {
      this.$RoomVariants
        .deleteResourceByUrl({url: variant["@id"]})
        .then((response) => {
          if (response.status === 204) {
            const toast = useToast();
            this.loadVariants();
            this.$helper.showToast(
              toast,
              Toast,
              this.$t("messages.roomVariantDeleted"),
              "",
              "success"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
    addNewVariant() {
      this.showVariantForm = true;
    },
    editVariant(variant) {
      this.variant = variant;
      this.showVariantForm = true;
    },
    addMealPlan(mp, variant) {
      mp.push({
        variant: variant["@id"],
        type: null,
        price: null,
        discountedPrice: null,
        priceOverrides: [],
        fromDate: null,
        toDate: null,
      });
    },
    showModal(mp) {
      this.selectedMealPlan = mp
      this.modalShow = true
    },
    setOverrideDates() {

      if (this.overrideFromDate && this.overrideToDate) {
        this.selectedMealPlan.priceOverrides = [];
        let loop = new Date(this.overrideFromDate);
        while (loop <= this.overrideToDate) {
          let date = new Date(loop);
          date = new Date(
            date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
          );
          const index = this.selectedMealPlan.priceOverrides.findIndex((o) =>
            this.dateMatch(date, new Date(o.date))
          );
          if (index !== -1) {
            this.selectedMealPlan.price = this.overridePrice
            this.selectedMealPlan.discountedPrice = this.overrideDiscountedPrice
          } else {
            this.selectedMealPlan.priceOverrides.push({
              date: date,
              price: this.overridePrice,
              discountedPrice: this.overrideDiscountedPrice
            });
          }

          const newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }
      }
    },
    dateMatch(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    },
    saveMealPlan(mps, mp, index) {
      const body = JSON.parse(JSON.stringify(mp));
      if (body.type) {
        body.type = body.type.id;
      }
      body.price = body.price.toString();
      body.discountedPrice = body.discountedPrice.toString();
      body.priceOverrides.forEach((po) => {
        delete po['@id'];
        delete po['@type'];
        delete po.id;
        delete po.mealPlans;
        po.price = po.price.toString();
        po.discountedPrice = po.discountedPrice.toString();
      });
      if (mp["@id"]) {
        this.$MealPlans
          .updateResourceByUrl({url: mp["@id"], body})
          .then((response) => {
            if (response.status === 200) {
              mp = response.data;
              if (mp.fromDate) {
                mp.fromDate = new Date(mp.fromDate);
              }
              if (mp.toDate) {
                mp.toDate = new Date(mp.toDate);
              }
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                this.$t("messages.mealPlanUpdated"),
                "",
                "success"
              );
              this.loadVariants();
            }
          })
          .catch((error) => {
            if (error.response) {
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          });
      } else {
        this.$MealPlans
          .createResource({body})
          .then((response) => {
            if (response.status === 201) {
              const data = response.data;
              data.type = {
                id: data.type,
                label: this.$helper.getEnumTranslation(
                  "meal_plan_type",
                  data.type
                ),
              };
              if (data.fromDate) {
                data.fromDate = new Date(data.fromDate);
              }
              if (data.toDate) {
                data.toDate = new Date(data.toDate);
              }
              mps[index] = data;
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                this.$t("messages.mealPlanCreated"),
                "",
                "success"
              );
              this.loadVariants();
            }
          })
          .catch((error) => {
            if (error.response) {
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          });
      }
    },
    removeMealPlan(mps, mp, index) {
      if (mp["@id"]) {
        this.$MealPlans
          .deleteResourceByUrl({url: mp["@id"]})
          .then((response) => {
            if (response.status === 204) {
              mps.splice(index, 1);
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                this.$t("messages.mealPlanDeleted"),
                "",
                "success"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          });
      } else {
        mps.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
