<template>
  <div
    @click="$emit('closed')"
    :class="{ active: show }"
    class="variant-form-overlay"
  ></div>
  <b-form
    class="variant-form"
    :class="{ active: show }"
    @submit.prevent="onSubmit"
  >
    <div class="variant-form-header">
      <span>{{ variant["@id"] ? "Update Variant" : "New Variant" }}</span>
      <bootstrap-icon @click="$emit('closed')" icon="x-lg"/>
    </div>

    <b-form-group
      label="Name"
      :class="{ row, error: v$.variant.name.$errors.length }"
    >
      <b-form-input v-model="variant.name"></b-form-input>
      <div
        class="input-errors"
        v-for="error of v$.variant.name.$errors"
        :key="error.$uid"
      >
        <small class="text-danger">{{ error.$message }}</small>
      </div>
    </b-form-group>

    <b-form-group
      label="Adult"
      :class="{ row, error: v$.variant.adults.$errors.length }"
    >
      <b-form-input v-model="variant.adults"></b-form-input>
      <div
        class="input-errors"
        v-for="error of v$.variant.adults.$errors"
        :key="error.$uid"
      >
        <small class="text-danger">{{ error.$message }}</small>
      </div>
    </b-form-group>

    <b-form-group
      label="Children"
      :class="{ row, error: v$.variant.children.$errors.length }"
    >
      <b-form-input v-model="variant.children"></b-form-input>
      <div
        class="input-errors"
        v-for="error of v$.variant.children.$errors"
        :key="error.$uid"
      >
        <small class="text-danger">{{ error.$message }}</small>
      </div>
    </b-form-group>

    <b-form-group
      label="Infants"
      :class="{ row, error: v$.variant.infants.$errors.length }"
    >
      <b-form-input v-model="variant.infants"></b-form-input>
      <div
        class="input-errors"
        v-for="error of v$.variant.infants.$errors"
        :key="error.$uid"
      >
        <small class="text-danger">{{ error.$message }}</small>
      </div>
    </b-form-group>

    <b-button @click="onSubmit" variant="primary">
      {{ variant.id ? "Save" : "Add" }}
    </b-button>
  </b-form>
</template>

<script>
import {BForm, BFormGroup, BButton} from "bootstrap-vue-3";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  props: {
    room: {type: String, required: true},
    variantObj: {type: Object, required: true},
    show: {type: Boolean, required: true},
  },
  components: {
    BForm,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      error: null,
      variant: this.variantObj,
    };
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      variant: {
        name: {required},
        children: {required},
        adults: {required},
        infants: {required}
      },
    };
  },
  watch: {
    variantObj(variant) {
      this.variant = variant;
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.variant.name,
          adults: parseInt(this.variant.adults, 10),
          children: parseInt(this.variant.children, 10),
          infants: parseInt(this.variant.infants, 10),
        };
        if (this.variant.room) {
          body.room = this.variant.room;
          this.$RoomVariants
            .updateResourceByUrl({url: this.variant['@id'], body})
            .then((response) => {
              if (response.status === 200) {
                const toast = useToast();
                this.$emit("closed");
                this.$emit("variantUpdated", response.data);
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.roomVariantUpdated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        } else {
          body.room = this.room
          this.$RoomVariants
            .createResource({body})
            .then((response) => {
              if (response.status === 201) {
                this.name = "";
                const toast = useToast();
                this.$emit("closed");
                this.$emit("variantCreated");
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.roomVariantCreated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }
      }
    },
  },
};
</script>
