<template>
  <div class="calendar-component">
    <a v-b-toggle :href="`#calendar-${index}`" @click.prevent>
      <span>{{ $t("forms.showCalendar") }}</span>
      <span>{{ $t("forms.hideCalendar") }}</span>
    </a>
    <b-collapse :id="`calendar-${index}`">
      <div class="calendar">
        <h5>
          {{ getMonthName() }} {{ year }}
          <div class="calendar-navigation">
            <bootstrap-icon @click="getMonth(-1)" icon="chevron-left" />
            <bootstrap-icon @click="getMonth(1)" icon="chevron-right" />
          </div>
        </h5>
        <div class="month">
          <div class="days">
            <div class="day" v-for="(day, index) of days" :key="index">
              <div
                class="day-name"
                :class="{
                  diff: day.disabled,
                }"
              >
                {{ getDayName(day.date) }}
              </div>
              <div class="inputs">
                <input
                  type="checkbox"
                  v-model="day.disabled"
                  @change="overrideChange(day)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-model="modalShow"
        modal-class="dates-dialog"
        cancel-title="Cancel"
        ok-title="Set dates"
        @ok="setDisabledRange()"
        hide-footer
      >
        <b-form-group label="From" class="me-4">
          <datepicker
            v-model="overrideFromDate"
            inputFormat="dd. MM. yyyy"
            class="form-input"
          />
        </b-form-group>
        <b-form-group label="To" class="me-4">
          <datepicker
            v-model="overrideToDate"
            inputFormat="dd. MM. yyyy"
            class="form-input"
          />
        </b-form-group>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('cancel')"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="setDisabledRange()"
          >
            SET
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="unsetDisabledRange()"
          >
            UNSET
          </button>
        </div>
      </b-modal>
      <b-button
        @click="modalShow = true"
        variant="gray"
        class="mt-auto"
      >
        {{ $t("forms.setRange") }}
      </b-button>
      <b-button @click="saveCalendar" variant="gray" class="my-2">{{
        $t("forms.save")
      }}</b-button>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse, BButton } from "bootstrap-vue-3";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Datepicker from "vue3-datepicker";

export default {
  components: {
    BCollapse,
    BButton,
    Datepicker
  },
  props: {
    roomObject: { type: Object, required: true },
  },
  watch: {
    roomObject: {
      handler() {
        this.setOverrides();
        this.getDaysInMonth();
      },
      deep: true,
    },
  },
  data() {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    date = new Date(
      date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
    );
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      days: [],
      index: uuidv4(),
      overrides: [],
      room: this.roomObject,
      modalShow: false,
      overrideFromDate: null,
      overrideToDate: null
    };
  },
  created() {
    if (!this.room.notBookableDates) {
      this.room.notBookableDates = [];
    }
    this.setOverrides();
    this.getDaysInMonth();
  },
  methods: {
    setOverrides() {
      if (this.room.notBookableDates && this.room.notBookableDates.length) {
        this.room.notBookableDates.forEach((nbDate) => {
          let date = new Date(nbDate);
          date = new Date(
            date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
          );
          nbDate = date;
        });
      }
    },
    setDisabledRange() {
      if(this.overrideFromDate && this.overrideToDate) {
        let loop = new Date(this.overrideFromDate);
        while(loop <= this.overrideToDate){
          let date = new Date(loop);
          date = new Date(
            date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
          );
          const index = this.room.notBookableDates.findIndex((o) =>
            this.dateMatch(date, new Date(o))
          );
          if (index === -1) {
            this.room.notBookableDates.push(date);
          }
          const newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);

        }
      }
    },
    unsetDisabledRange() {
      if(this.overrideFromDate && this.overrideToDate) {
        let loop = new Date(this.overrideFromDate);
        while(loop <= this.overrideToDate){
          let date = new Date(loop);
          date = new Date(
            date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
          );
          const found = this.room.notBookableDates.findIndex((o) =>
            this.dateMatch(date, new Date(o))
          );
          if (found !== -1) {
            this.room.notBookableDates.splice(found, 1);
          }
          const newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);

        }
      }
    },
    getDaysInMonth() {
      let date = new Date(this.year, this.month, 1);
      date = new Date(
        date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
      );
      var days = [];
      while (date.getMonth() === this.month) {
        const d = {
          date: new Date(date.getTime()),
          disabled: false,
        };

        // ak je v overrides then override
        const index = this.room.notBookableDates.findIndex((o) =>
          this.dateMatch(d.date, new Date(o))
        );
        if (index !== -1) {
          d.disabled = true;
        }
        days.push(d);
        date.setDate(date.getDate() + 1);
      }
      this.days = days;
    },
    getMonthName() {
      return moment.months(this.month);
    },
    getDayName(date) {
      return moment(date).format("dd") + " " + moment(date).format("DD");
    },
    getMonth(offset) {
      let newDate = new Date(this.year, this.month + offset, 1);
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
      newDate = new Date(
        newDate.getTime() + Math.abs(newDate.getTimezoneOffset() * 60000)
      );
      this.year = newDate.getFullYear();
      this.month = newDate.getMonth();
      this.getDaysInMonth();
    },
    overrideChange(day) {
      const found = this.room.notBookableDates.findIndex((element) =>
        this.dateMatch(new Date(element), day.date)
      );
      if (!day.disabled) {
        this.room.notBookableDates.splice(found, 1);
      }
      if (day.disabled) {
        this.room.notBookableDates.push(day.date);
      }
    },
    dateMatch(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    },
    saveCalendar() {
      const dates = []
      this.room.notBookableDates.forEach(element => {
        const dateString = new Date(element).toISOString().substring(0,10)
        dates.push(dateString)
      });
      const body = {
        notBookableDates: dates,
      };
      this.$Rooms
        .updateResourceByUrl({ url: this.room["@id"], body })
        .then((response) => {
          if (response.status === 200) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              this.$t("messages.roomUpdated"),
              "",
              "success"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            const toast = useToast();
            this.$helper.showToast(
              toast,
              Toast,
              error.response.data["hydra:title"],
              error.response.data["hydra:description"],
              "danger"
            );
          }
        });
    },
  },
};
</script>
