<template>
  <b-form class="resort-form pt-4" @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
            :label="$t('forms.room')"
            label-size="lg"
            label-cols-lg="4"
            content-cols-lg="8"
            class="align-items-center"
            :class="{ row, error: v$.room.name.$errors.length }"
          >
            <b-form-input v-model="room.name"></b-form-input>
            <div
              class="input-errors"
              v-for="error of v$.room.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.maxAdults')"
            label-cols-lg="4"
            content-cols-lg="8"
            :class="{ row, error: v$.room.adults.$errors.length }"
          >
            <b-form-input v-model="room.adults"></b-form-input>
            <div
              class="input-errors"
              v-for="error of v$.room.adults.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.maxInfants')"
            label-cols-lg="4"
            content-cols-lg="8"
            :class="{ row, error: v$.room.infants.$errors.length }"
          >
            <b-form-input v-model="room.infants"></b-form-input>
            <div
              class="input-errors"
              v-for="error of v$.room.infants.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.maxChildren')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.room.children.$errors.length }"
          >
            <b-form-input v-model="room.children"></b-form-input>
            <div
              class="input-errors"
              v-for="error of v$.room.children.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
          <b-form-group
            :label="$t('forms.accommodationType')"
            label-cols-lg="4"
            content-cols-lg="4"
            :class="{ row, error: v$.room.accommodationType.$errors.length }"
          >
            <v-select
              :searchable="false"
              :clearable="false"
              :options="accTypes"
              v-model="room.accommodationType"
            >
            </v-select>
            <div
              class="input-errors"
              v-for="error of v$.room.accommodationType.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        :label="$t('forms.nonBookableDates')"
        label-cols-lg="2"
        content-cols-lg="10"
        class="row"
      >
        <calendar v-if="room.id" :roomObject="room"/>
      </b-form-group>
      <b-tabs v-model="tabIndex" v-if="room['@id']">
        <b-tab v-for="tab of tabs" :key="tab.id" :title="tab.title"></b-tab>
      </b-tabs>
      <div v-if="tabIndex === 0">
        <b-card class="card-gray mt-4">
          <h5 class="mb-2">{{ $t("forms.content") }}</h5>
          <b-form-group>
            <label class="label-sm mb-1">{{ $t("forms.description") }}</label>
            <tinymce
              :options="{ height: 200 }"
              :content="room.description"
              @editorContentChanged="updateDescription"
            />
          </b-form-group>
          <b-form-group>
            <label class="label-sm mb-1">{{ $t("forms.additionalBenefits") }}</label>
            <tinymce
              :options="{ height: 200 }"
              :content="room.additionalBenefits"
              @editorContentChanged="updateAdditionalBenefits"
            />
          </b-form-group>
        </b-card>
        <gallery
          v-if="this.room['@id']"
          v-model="file"
          :single="true"
          :resource="this.room['@id']"
          file-attribute="FILE"
          :galleryTitle="$t('forms.mainImage')"
        />
        <gallery
          v-if="this.room['@id']"
          v-model="files"
          :resource="this.room['@id']"
          file-attribute="FILES"
          :galleryTitle="$t('forms.imageGallery')"
        />
        <b-card class="card-gray my-4 facilities_services">
          <h5 class="mb-4">{{ $t("forms.amenities") }}</h5>
          <b-card
            class="no-box-shadow mb-4"
            v-if="room.amenitiesFacilities && room.amenitiesFacilities.length"
          >
            <div
              v-for="(af, index) of room.amenitiesFacilities"
              :key="index"
              class="facility d-flex align-items-center"
              :class="{ 'mb-2': index !== room.amenitiesFacilities.length - 1 }"
            >
              <v-select
                :searchable="false"
                :clearable="false"
                :options="amenities"
                v-model="af.type"
              >
              </v-select>
              <b-form-input v-model="af.description" class="mx-4"/>
              <b-form-checkbox v-model="af.additionalCharges">{{
                  $t("forms.charge")
                }}
              </b-form-checkbox>
              <bootstrap-icon @click="removeAmenity(index)" icon="x-lg"/>
            </div>
          </b-card>
          <b-button @click="addAmenity" variant="gray">
            <bootstrap-icon icon="plus"/>
            {{ $t("forms.addItem") }}
          </b-button>
        </b-card>
      </div>
      <room-rates v-if="tabIndex === 1 && room['@id']" :room="room['@id']"/>
      <b-button variant="primary" @click="onSubmit">
        <bootstrap-icon v-if="!room.id" icon="plus"/>
        {{ !room.id ? $t("messages.roomAdd") : $t("messages.roomSave") }}
      </b-button>
    </b-container>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import Toast from "@/components/Toast.vue";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import RoomRates from "./RoomRates.vue";
import Gallery from "./Gallery.vue";
import {required} from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Calendar from "./CalendarDisabled.vue";

export default {
  props: {
    roomObj: {
      type: Object, default() {
      }
    },
  },
  components: {
    vSelect,
    tinymce,
    RoomRates,
    Gallery,
    Calendar
  },
  data() {
    return {
      success: false,
      room: this.roomObj,
      accTypes: [],
      error: null,
      tabs: [
        {
          id: 0,
          title: this.$t("forms.content"),
        },
        {
          id: 1,
          title: this.$t("forms.rates"),
        },
      ],
      tabIndex: 0,
      amenities: [],
      files: [],
      file: null,
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    this.getEnumList("accommodation_facility_type", this.accTypes);
    this.getEnumList("amenity_facility_type", this.amenities);
    this.load();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      room: {
        name: {required},
        adults: {required},
        infants: {required},
        children: {required},
        accommodationType: {required},
      },
    };
  },
  methods: {
    load() {
      this.setSelectDataForRoom();
      if (this.room.id) {
        this.$Rooms
          .getResourceFiles({id: this.room.id, params: {pagination: false, width: 800, format: 'jpg'}})
          .then((response) => {
            if (response.status === 200) {
              this.files = response.data["hydra:member"];
            }
          })
          .catch((error) => {
            if (error.response) {
              const toast = useToast();
              this.$helper.showToast(
                toast,
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          });
      }
      if (this.room.accommodationType) {
        this.room.accommodationType = {
          id: this.room.accommodationType,
          label: this.$helper.getEnumTranslation(
            "accommodation_facility_type",
            this.room.accommodationType
          ),
        };
      }
      if (this.room.file) {
        this.file = {
          '@id': this.room.file,
          'url': this.room.fileUrl,
          'id': this.room.fileId,
          'mimeType': 'image'
        }
      }
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          resort: this.room.resort,
          adults: parseInt(this.room.adults, 10),
          children: parseInt(this.room.children, 10),
          infants: parseInt(this.room.infants, 10),
          name: this.room.name,
          description: this.room.description,
          additionalBenefits: this.room.additionalBenefits,
          accommodationType: this.room.accommodationType
            ? this.room.accommodationType.id
            : null,
        };
        if (
          this.room.amenitiesFacilities &&
          this.room.amenitiesFacilities.length
        ) {
          body.amenitiesFacilities = [];
          this.room.amenitiesFacilities.forEach((af) => {
            body.amenitiesFacilities.push({
              type: af.type.id,
              description: af.description,
              additionalCharges: af.additionalCharges,
            });
          });
        }
        if (this.room["@id"]) {
          this.$Rooms
            .updateResourceByUrl({url: this.room["@id"], body})
            .then((response) => {
              if (response.status === 200) {
                this.room = response.data;
                this.load();
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.roomUpdated"),
                  "",
                  "success"
                );

              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        } else {
          this.$Rooms
            .createResource({body})
            .then((response) => {
              if (response.status === 201) {
                this.room = response.data;
                this.load();
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  this.$t("messages.roomCreated"),
                  "",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                const toast = useToast();
                this.$helper.showToast(
                  toast,
                  Toast,
                  error.response.data["hydra:title"],
                  error.response.data["hydra:description"],
                  "danger"
                );
              }
            });
        }
      }
    },
    updateDescription(content) {
      this.room.description = content;
    },
    updateAdditionalBenefits(content) {
      this.room.additionalBenefits = content;
    },
    setSelectDataForRoom() {
      if (
        this.room.amenitiesFacilities &&
        this.room.amenitiesFacilities.length
      ) {
        this.room.amenitiesFacilities.forEach((af) => {
          af.type = {
            id: af.type,
            label: this.$helper.getEnumTranslation(
              "amenity_facility_type",
              af.type
            ),
          };
        });
      }
    },
    addAmenity() {
      if (!this.room.amenitiesFacilities) {
        this.room.amenitiesFacilities = [];
      }
      this.room.amenitiesFacilities.push({
        type: null,
        description: "",
        additionalCharges: false,
        room: this.room["@id"] ? this.room["@id"] : null,
      });
    },
    removeAmenity(index) {
      this.room.amenitiesFacilities.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
